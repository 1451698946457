import { useDispatch } from "react-redux";
import { openNotification } from "../redux/actions/notification";

function useClipboard() {
  const dispatch = useDispatch();

  const copy = async (text: string): Promise<boolean> => {
    if (!navigator?.clipboard) {
      await dispatch(
        openNotification({
          title: "Clipboard",
          description: "Clipboard not supported",
          btnText: "Close",
          closeOnBtnClick: true,
        })
      );
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (error) {
      await dispatch(
        openNotification({
          title: "Clipboard",
          description: "Something went wrong",
          btnText: "Close",
          closeOnBtnClick: true,
        })
      );
      return false;
    }
  };

  const copyLink = async (e: React.MouseEvent, path: string) => {
    e.preventDefault();
    await copy(path);
  };

  return { copyLink };
}

export default useClipboard;
