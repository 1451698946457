import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from "@aws-sdk/client-secrets-manager";

// a client can be shared by different commands.
const client = new SecretsManagerClient({
  region: process.env.KLOOV_AWS_REGION,
  credentials: {
    accessKeyId: process.env.KLOOV_AWS_ACCESS_KEY ?? "",
    secretAccessKey: process.env.KLOOV_AWS_ACCESS_SECRET ?? "",
  },
});

const getSecretValue = async (secretId: string) => {
  const command = new GetSecretValueCommand({
    SecretId: secretId,
  });
  const { SecretString } = await client.send(command);
  return SecretString;
};

export const getSecretConfig = async <T>(secretId: string): Promise<T> => {
  const value = await getSecretValue(secretId);

  if (!value) {
    throw new Error(`Secret "${secretId}" has an empty value`);
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    throw new Error(`Unable to decode secret "${secretId}"`);
  }
};
