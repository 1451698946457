import { UAParser } from "ua-parser-js";

const useUserAgent = () => {
  const parser = new UAParser();

  const isMobile = parser.getDevice().type === "mobile";

  const isTablet = parser.getDevice().type === "tablet";

  const isMobileOrTablet = isMobile || isTablet;

  return { isMobile, isTablet, isMobileOrTablet };
};

export default useUserAgent;
