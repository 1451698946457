import { DAY_IN_MS } from "@/shared/constants/time";
import { useEffect, useState } from "react";
import { formatShortDateText, formatTimeDateString } from "@/shared/utils/date";

export interface CountdownState {
  timerDays: string;
  timerHours: string;
  timerMinutes: string;
  timerSeconds: string;
  dayHourText: () => string;
  formattedDateText: string;
  dateText: string;
}
const useCountdown = (
  startDate: string | undefined,
  endDate?: string,
  includeDays = true
) => {
  const [startCountdown, setStartCountdown] = useState<CountdownState>();
  const [endCountdown, setEndCountdown] = useState<CountdownState>();

  const [isStartCountDownActive, setIsStartCountDownActive] = useState<
    boolean | null
  >(null);
  const [isEndCountDownActive, setIsEndCountDownActive] = useState<
    boolean | null
  >(null);

  const isLoadedCountdown =
    isStartCountDownActive !== null || isEndCountDownActive !== null;

  useEffect(() => {
    let interval: NodeJS.Timer;
    const startTimer = () => {
      if (!startDate) {
        return;
      }

      const startCountdownDate = new Date(startDate).getTime();
      const endCountdowndate = new Date(endDate ?? "").getTime();

      interval = setInterval(() => {
        const now = new Date().getTime();
        const startDistance = startCountdownDate - now;

        const days = Math.floor(includeDays ? startDistance / DAY_IN_MS : 0);
        const hours = Math.floor(
          (!includeDays ? startDistance : startDistance % DAY_IN_MS) /
            (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (startDistance % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((startDistance % (1000 * 60)) / 1000);

        const dayHourText = (): string => {
          if (includeDays) {
            if (Number(days) > 1) {
              return "Days";
            }
            return "Day";
          } else {
            if (Number(hours) > 1) {
              return "Hours";
            }
            return "Hour";
          }
        };

        if (startDistance < 0) {
          setIsStartCountDownActive(false);
          clearInterval(interval);
        } else {
          if (endDate) {
            if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
              setIsEndCountDownActive(false);
              clearInterval(interval);
            }

            setStartCountdown({
              timerDays: days > 9 ? days.toString() : "0" + days,
              timerHours: hours > 9 ? hours.toString() : "0" + hours,
              timerMinutes: minutes > 9 ? minutes.toString() : "0" + minutes,
              timerSeconds: seconds > 9 ? seconds.toString() : "0" + seconds,
              formattedDateText: formatTimeDateString(
                days,
                hours,
                minutes,
                seconds
              ),
              dayHourText: dayHourText,
              dateText: formatShortDateText(startDate),
            });
            setIsStartCountDownActive(true);
          } else {
            if (startDistance < 0) {
              setIsStartCountDownActive(false);
              clearInterval(interval);
            } else {
              const dayHourText = (): string => {
                if (includeDays) {
                  if (Number(days) > 1) {
                    return "Days";
                  }
                  return "Day";
                } else {
                  if (Number(hours) > 1) {
                    return "Hours";
                  }
                  return "Hour";
                }
              };

              setStartCountdown({
                timerDays: days > 9 ? days.toString() : "0" + days,
                timerHours: hours > 9 ? hours.toString() : "0" + hours,
                timerMinutes: minutes > 9 ? minutes.toString() : "0" + minutes,
                timerSeconds: seconds > 9 ? seconds.toString() : "0" + seconds,
                formattedDateText: formatTimeDateString(
                  days,
                  hours,
                  minutes,
                  seconds
                ),
                dayHourText: dayHourText,
                dateText: formatShortDateText(startDate),
              });

              setIsStartCountDownActive(true);
            }
          }
        }

        if (endDate) {
          const now = new Date().getTime();
          const distance = endCountdowndate - now;

          const days = Math.floor(includeDays ? distance / DAY_IN_MS : 0);
          const hours = Math.floor(
            (!includeDays ? distance : distance % DAY_IN_MS) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          if (distance < 0) {
            setIsEndCountDownActive(false);
            clearInterval(interval);
          } else {
            const dayHourText = (): string => {
              if (includeDays) {
                if (Number(days) > 1) {
                  return "Days";
                }
                return "Day";
              } else {
                if (Number(hours) > 1) {
                  return "Hours";
                }
                return "Hour";
              }
            };

            setEndCountdown({
              timerDays: days > 9 ? days.toString() : "0" + days,
              timerHours: hours > 9 ? hours.toString() : "0" + hours,
              timerMinutes: minutes > 9 ? minutes.toString() : "0" + minutes,
              timerSeconds: seconds > 9 ? seconds.toString() : "0" + seconds,
              formattedDateText: formatTimeDateString(
                days,
                hours,
                minutes,
                seconds
              ),
              dayHourText,
              dateText: formatShortDateText(endDate),
            });
            setIsEndCountDownActive(true);
          }
        }
      }, 1000);

      if (endDate) {
        const endCountdowndate = new Date(endDate ?? "").getTime();
        interval = setInterval(() => {
          const now = new Date().getTime();
          const distance = endCountdowndate - now;

          const days = Math.floor(includeDays ? distance / DAY_IN_MS : 0);
          const hours = Math.floor(
            (!includeDays ? distance : distance % DAY_IN_MS) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          if (distance < 0) {
            setIsEndCountDownActive(false);
            clearInterval(interval);
          } else {
            const dayHourText = (): string => {
              if (includeDays) {
                if (Number(days) > 1) {
                  return "Days";
                }
                return "Day";
              } else {
                if (Number(hours) > 1) {
                  return "Hours";
                }
                return "Hour";
              }
            };

            setEndCountdown({
              timerDays: days > 9 ? days.toString() : "0" + days,
              timerHours: hours > 9 ? hours.toString() : "0" + hours,
              timerMinutes: minutes > 9 ? minutes.toString() : "0" + minutes,
              timerSeconds: seconds > 9 ? seconds.toString() : "0" + seconds,
              formattedDateText: formatTimeDateString(
                days,
                hours,
                minutes,
                seconds
              ),
              dayHourText,
              dateText: formatShortDateText(endDate),
            });
          }
        }, 1000);
      }
    };
    startTimer();
    return () => {
      clearInterval(interval);
    };
  }, [startDate, includeDays, endDate]);

  return {
    isLoadedCountdown,
    startCountdown,
    endCountdown,
    isStartCountDownActive,
    isEndCountDownActive,
  };
};

export default useCountdown;
