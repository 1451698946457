export const formatShortDateText = (timestamp: string) => {
  const dateText = new Date(timestamp).toDateString().split(" ");
  return `${dateText[2]} ${dateText[1]}`;
};

export const formatTimeDateString = (
  days: number,
  hours: number,
  minutes: number,
  seconds: number
) => {
  return `${days > 0 ? days + "d" : ""} ${hours > 0 ? hours + "h" : ""} ${
    minutes > 0 ? minutes + "m" : ""
  } ${seconds > 0 ? seconds + "s" : ""}`;
};

export const isSameDay = (d1: Date, d2: Date) => {
  return (
    d1.toLocaleString().split(",")[0] === d2.toLocaleString().split(",")[0]
  );
};
